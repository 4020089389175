/* ------------------------------------*\
  #Imports
\*------------------------------------ */
@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.WidgetWrapper {
  background: $purpleDarker;
  color: $white;
  overflow: hidden;
  padding: 5px 20px;
  position: relative;
  text-align: center;
}

.PositionOverlay {
  position: relative;
}

.PositionStatic {
  position: relative;

  .WidgetWrapper {
    margin-top: 60px;
  }
}

.WidgetLink {
  height: 100%;
  left: 0;
  position: absolute;
  text-indent: -9999px;
  top: 0;
  width: 100%;
}

.Headline {
  font-size: $fontSize-m;
  font-weight: $font-weight-m;
  line-height: 20px;
}

.TimeHolder {
  display: inline;
  font-size: $fontSize-m;
  font-weight: $font-weight-l;
  line-height: 20px;
  text-transform: uppercase;

  div {
    display: inline-block;
    font-weight: $font-weight-s;
    margin: 0 15px 0 0;
  }
}

.DisplayInline {
  display: inline-block;
  margin-right: 30px;
}

@media screen and  ( $tablet-media ) {

  .PositionOverlay {

    & > div {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .PositionStatic {

    .WidgetWrapper {
      margin-top: 50px;
    }
  }
}

@media screen and  ( $mobile-media ) {

  .DisplayInline {
    display: block;
    margin-right: 0;
  }

  .TimeHolder {

    span {
      display: none;
    }

    div {
      margin-right: 10px;
    }
  }

  .WidgetWrapper {
    padding: 5px 10px;
  }

  .PositionStatic {

    & > div {
      padding-left: 0;
      padding-right: 0;
    }

    .WidgetWrapper {
      margin-top: 30px;
    }
  }
}
