/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.ContactFormRequired {
  bottom: 70px;
  color: $redError;
  font-size: 12px;
  letter-spacing: 0.5px;
  margin: 0 0 0 35px;
}

.ContactFormTitle {
  color: $grey;
  font-family: $fontFamily;
  font-size: $fontSize-sm;
  padding: 20px 0;
}

.ContactFormInner {
  background: $greyLightestPlus;
  border: 1px solid $greyLightest;
  border-radius: 8px;
  padding: 29px 26px;
  position: relative;

  form {
    padding: 0 68px;
  }
}

.ContactFormSubheader {

  h4 {
    font-size: $fontSize-xl;
    margin: 0 0 32px;
  }
}

.ContactFormTerms {

  div > label {
    font-weight: $font-weight-m;
  }

  span span {
    cursor: pointer;
    text-decoration: underline;
  }
}

.ContactFormButtons {
  display: flex;
  justify-self: flex-end;
  margin: 35px 0 0;

  button {
    min-width: 150px;
    padding: 9px 16px;
  }
}

.ContactFormInfo,
.ContactFormError {
  background: $orangeInfoAlpha;
  border: 1px solid $orangeInfo;
  border-radius: 8px;
  margin: 20px 0 -20px;
  padding: 24px 48px;
  position: relative;
}

.ContactFormError {
  background: $redErrorAlpha;
  border: 1px solid $redError;

  .ContactFormIcon {

    &:before {
      color: $redError;
    }
  }
}

.ContactFormIcon {
  position: absolute;
  right: 14px;
  top: 16px;

  &:before {
    color: $orangeInfo;
    font-size: $fontSize-Icon24;
  }
}

.ContactFormMessage {
  font-size: $fontSize-sm;
}

.ContactFormFieldDropdown {
  margin-bottom: 10px;
}

.ContactFormFieldDropdownError {

  :global {

    .themed-dropdown {
      background: $redErrorAlpha !important;
      border-color: $redError;
    }

    .themed-dropdown-label {
      color: $greyLighter !important;
      font-style: italic;
    }

    svg {

      path {
        stroke: $redError;
      }
    }
  }
}

.ContactFormFieldDropdownPlaceholder {

  :global {

    .themed-dropdown-label {
      color: $greyLighter !important;
      font-style: italic;
    }
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $desktop-only-media ) {

  .ContactFormTitle {
    margin: 0 auto;
    width: 720px;
  }

  .ContactFormInner {
    margin: 0 auto;
    max-width: 100%;
    width: 720px;
  }
}

@media screen and ( $above-mobile-media ) {

  .ContactFormOneLineFields {
    display: flex;
    justify-content: space-between;

    & > .ContactFormField {
      flex-grow: 1;

      &:not(:first-child) {
        margin-left: 8px;
      }

      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
}

@media screen and ( $tablet-media ) {

  .ContactFormInner {
    margin: 0 auto;
    max-width: 100%;
    width: 720px;
  }
}

@media screen and ( $mobile-media ) {

  .ContactFormInner {

    form {
      padding: 0;
    }
  }

  .ContactFormButtons {
    width: 100%;
  }
}
