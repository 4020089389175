/* ------------------------------------*\
  #Imports
\*------------------------------------ */
@import '../../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.CmsQuoteColumn {
  grid-row-gap: 25px;
  padding-top: 25px;
}

.CmsQuoteStars {
  margin-bottom: 5px;
}

.CmsQuoteSlide {
  overflow: hidden;
  position: relative;

  :global {

    .navigation-wrapper {
      display: flex;
      flex-direction: column-reverse;
      padding-bottom: 48px;
      padding-top: 10px;
    }

    .dots {
      bottom: 20px;
      padding: 0;
      position: absolute;

      .dot {
        background: $greyLightest;
        border: 0;
        height: 12px;
        width: 12px;

        &.active {

          &:after {
            height: 6px;
            width: 6px;
          }
        }
      }
    }
  }
}

.CmsQuote {
  font-family: $fontFamily;
  font-size: $fontSize-m;
  line-height: 0.7;
  outline: none;
  padding: 0 40px 10px;
  text-align: center;

  > div:first-of-type {
    margin-bottom: 5px;
  }

  div {
    line-height: 1.5;
  }

  b {
    display: block;
    font-family: $fontFamily;
    font-size: $fontSize-xl;
    margin-bottom: 5px;
  }

  small {
    color: $greyLighter;
    font-family: $fontFamily;
    font-style: normal;
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $mobile-media ) {

  .CmsQuote {
    padding: 20px 5px 0;
  }
}
