/* ------------------------------------*\
  #Imports
\*------------------------------------ */
@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.FontColorSecondary {
  color: $white;

  p,
  small {
    color: $white;
  }

  hr {
    border-color: $white;
  }
}

.columnImageHolder {
  display: block;
}

.Benefits {

  img {
    margin: 0 auto;
    max-width: 100px;
  }

  span, small {
    color: $black;
    display: block;
    font-size: $fontSize-s;
    margin: 0 auto;
    max-width: 150px;
    text-align: center;
  }
}

.TypeArea {
  margin: auto;
  max-width: 650px;
}

.TextAlign {
  justify-content: center;
  text-align: center;

  a {
    margin: 20px auto;
  }
}

.SpaceRemoval {
  margin-bottom: -65px;

  & + [ class *= 'AuthorBox' ] {
    margin-top: 50px;
  }
}

.ColumnFlexOff {
  display: block !important;
}

.HorizontalAlign {
  align-content: normal;
  align-items: center;
  display: flex;
}

.oneColumnRow,
.twoColumnRow,
.threeColumnRow,
.fourColumnRow,
.fourInfoboxColumnRow {
  padding-bottom: 40px;
  padding-top: 40px;
  width: 100%;
}

.oneColumnNoPadding {
  padding-bottom: 0;
  padding-top: 0;
}

.twoColumnRow,
.threeColumnRow,
.fourColumnRow,
.fourInfoboxColumnRow {
  display: grid;
  grid-gap: 100px 20px;
}

.infoBoxStyle {
  border-radius: 8px;
  padding: 18px;
}

.twoColumnRow {
  grid-template-columns: 50% 50%;
  max-width: calc(100% - 20px);

  > div {
    width: 100%;
  }

  .TextAboveImageContainer {
    position: relative;
    width: 100%;

    .TextAboveImage {
      align-content: center;
      background: $transparentWhite;
      display: flex;
      flex-wrap: wrap;
      height: 90%;
      left: 50%;
      padding: 25px;
      position: absolute;
      top: 49%;
      transform: translateX(-50%) translateY(-50%);
      width: 90%;
    }
  }

  .IconAboveText {
    height: 100px;
    margin: 0 auto;
    width: 100px;
  }
}

.threeColumnRow {
  grid-template-columns: 33% 33% 33%;
  max-width: calc(100% - 40px);
}

.fourInfoboxColumnRow,
.fourColumnRow {
  grid-template-columns: 25% 25% 25% 25%;
  max-width: calc(100% - 60px);

  .Column {
    height: 100%;
  }
}

.twoOneColumn {
  grid-template-columns: 66% 33%;
}

.oneTwoColumn {
  grid-template-columns: 33% 66%;
}

.AccordionItem {
  cursor: pointer;
  width: 100%;

  .AccordionContent {
    max-height: 0;
    opacity: 0;
    padding: 10px 25px 0 15px;
    pointer-events: none;
    transform: translateY(-20px);
    transition: all .3s $defaultBezier;

    > * {
      pointer-events: none;
    }

    a {
      pointer-events: all;
    }
  }

  &.Active {

    .AccordionContent {
      max-height: 4000px;
      opacity: 1;
      padding-bottom: 10px;
      pointer-events: initial;
      transform: translateY(0);
    }

    .AccordionHead {

      &:after {
        opacity: .3;
        transform: translateY(0%) rotate(-180deg);
      }
    }
  }

  .AccordionHead {
    padding-right: 30px;
    position: relative;

    &:after {
      color: $grey;
      font-size: $fontSize-Icon32;
      line-height: 20px;
      margin-top: -10px;
      opacity: 1;
      position: absolute;
      right: -6px;
      top: 50%;
      transition: all .3s $defaultBezier;
    }
  }
}

.qaContainer {
  background: $white;
  border: 1px solid $greyLightest;
  border-radius: 8px;
  margin: 50px auto;
  max-width: 600px;
  padding: 48px;

  h2 {
    margin-bottom: 15px;
  }

  button {
    max-width: 250px;

    &[ type = 'submit' ] {
      margin-left: auto;
      margin-top: 15px;
    }
  }
}

.qaContainerThankYou {
  background: $white;
  border: 1px solid $greyLightest;
  border-radius: 8px;
  margin: 40px auto 0;
  max-width: 600px;
  padding: 48px;
}

.qaFormTerms {
  padding-bottom: 10px;
  position: relative;

  :global {

    .normal {

      span {
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }
}

.qaFormRequired {
  bottom: 5px;
  color: $redError;
  font-size: 12px;
  letter-spacing: 0.5px;
  margin: 0 0 0 35px;
  position: absolute;

  + div {
    padding-bottom: 20px;
  }

  + div span {
    color: $redError;
  }
}

.Column {
  align-content: flex-start;
  display: flex;
  flex-wrap: wrap;

  > div {
    width: 100%;
  }

  a {
    margin-top: 20px;
    max-width: 250px;
  }
}

.RowSlider {
  overflow: hidden;
  position: relative;

  :global {

    .navigation-wrapper {
      display: flex;
      flex-direction: column-reverse;
      padding-bottom: 48px;
      padding-top: 40px;
    }

    .dots {
      bottom: 20px;
      padding: 0;
      position: absolute;

      &.element-column {
        text-align: center;

        .dot {
          display: inline-grid;
        }
      }

      .dot {
        background: $greyLightest;
        border: 0;
        height: 12px;
        width: 12px;

        &:after {
          height: 6px;
          width: 6px;
        }

        &.active {

          &:after {
            height: 8px;
            width: 8px;
          }
        }
      }
    }
  }
}

.RowSlide {
  overflow: hidden;
  padding-bottom: 44px;
  padding-top: 44px;

  .RowColumn {
    align-content: space-between;
    display: flex !important;
    flex-wrap: wrap;
    min-height: calc(100% - 5px);
    outline: none;

    a {
      margin-top: 20px;
    }
  }
}

.RowFullSlide {
  overflow: visible;
}

.SliderWrapper {
  padding-bottom: 20px;
  padding-top: 20px;
  position: relative;

  :global {

    .navigation-wrapper {
      margin: 0 -40px;
      padding: 24px 40px;

      &:before, &:after {
        background: $white;
        content: '';
        display: block;
        height: 100%;
        position: absolute;
        top: 0;
        width: 40px;
        z-index: 2;
      }

      &:before {
        left: 0;
      }

      &:after {
        right: 0;
      }
    }

    .dots {
      bottom: 0;

      &.elements {
        position: relative;
        text-align: center;
      }

      .dot {
        background: $greyLightest;
        border: 0;
        height: 12px;
        width: 12px;

        &.active {

          &:after {
            height: 6px;
            width: 6px;
          }
        }
      }
    }
  }
}

.Arrow {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  display: block;
  height: 50px;
  margin-top: -25px;
  outline: none;
  position: absolute;
  top: 50%;
  transition-duration: 0.4s;
  width: 50px;
  z-index: 3;

  &.ArrowDisabled {
    cursor: default;
    opacity: 0.4;
    pointer-events: none;
  }

  &:after {
    display: none;
  }
}

.ArrowPrev {
  background-image: url($basePathImages + 'slider/arrow-left.svg');
  left: 5px;

  &:not(.ArrowDisabled) {

    &:hover {
      background-position-x: -10px;
    }
  }
}

.ArrowNext {
  background-image: url($basePathImages + 'slider/arrow.svg');
  right: 5px;

  &:not(.ArrowDisabled) {

    &:hover {
      background-position-x: 10px;
    }
  }
}

.CmsProductList {

  > div:first-of-type {

    > div {
      margin: 0;
      width: 25%;
    }
  }
}

.CmsProductFilterBtn {
  margin: 0 auto;
  max-width: 250px;
}

:global {

  .FullSizeColumnRow {

    img {
      height: 440px;
      max-width: 100%;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }
}

.HeaderHolder {
  margin-bottom: 20px;
  padding: 40px 0 0;

  b {
    color: $purple;
  }

  > *:empty {
    display: none;
  }
}

.SubHeaderHolder {
  margin-bottom: 40px;
}

.AlignCenter {
  text-align: center;
}

.NewsletterWidget {
  margin: 40px 0;
}

.NewsletterContainer {
  padding-bottom: 40px;
}

.TextHolder {
  padding: 40px 0;
  position: relative;

  ul {
    list-style: none;
    margin-bottom: 20px;
    padding: 0;

    li {
      line-height: 20px;
      margin: 8px 0;
      padding-left: 26px;
      position: relative;

      &:before {
        @extend .icomoon-font !optional;
        content: $iconChecker;
        display: block;
        font-size: 12px;
        left: 0;
        position: absolute;
        top: 4px;
      }
    }
  }
}

.PaddingBox {
  margin: 20px 0;
  padding: 40px;
}

.PosterHolder {
  margin: 40px 0;

  img {
    display: block;
    height: auto;
    width: 100%;
  }
}

.BgImageBox {
  margin: 20px 0;
  padding: 0;

  :global {

    .bgImg-holder {
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      left: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
    }
  }

  > div {

    &:nth-child(2) {
      padding: 40px;
      position: relative;
      z-index: 1;
    }
  }
}

.WhiteText {
  color: $white;
}

.AuthorBox {
  margin: 24px 0 0;
  position: relative;
}

.AuthorBoxWrap {
  align-content: center;
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
}

.AuthorBoxImage {
  border-radius: 50%;
  flex: 0 0 48px;
  height: 48px;
  margin-right: 12px;
  overflow: hidden;
  position: relative;

  img {
    display: block;
    height: 100%;
    object-fit: cover;
    position: absolute;
    width: 100%;
  }
}

.AuthorBio {

  p {
    color: $greyDarkest;
    font-size: $fontSize-m;
    line-height: 1;

    small {
      font-size: $fontSize-s;
    }

    & + p {
      margin-top: 4px;
    }
  }
}

.LastSeenProductsWrapper {
  margin-top: 50px;
  padding-top: 5px;
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .ArrowPrev {
    left: 10px;

    &:not(.ArrowDisabled) {

      &:hover {
        background-position-x: -5px;
      }
    }
  }

  .ArrowNext {
    right: 10px;

    &:not(.ArrowDisabled) {

      &:hover {
        background-position-x: 5px;
      }
    }
  }

  .twoColumnRow {

    .TextAboveImageContainer {

      .TextAboveImage {
        height: 95%;
        top: 49.5%;
        width: 95%;

        h2 {
          font-size: $fontSize-lm;
        }
      }
    }

    .IconAboveText {
      height: 80px;
      width: 80px;
    }
  }

  .fourInfoboxColumnRow,
  .fourColumnRow {
    grid-row-gap: 25px;
    grid-template-columns: 50% 50%;
    max-width: calc(100% - 20px);
  }

  .CmsProductList {

    > div:first-of-type {

      > div {
        width: 33%;
      }
    }
  }

  /* stylelint-disable-next-line */

  _:-ms-lang(x),
  .fourInfoboxColumnRow,
  .fourColumnRow {
    grid-row-gap: 100px;
  }

  :global {

    .FullSizeColumnRow {

      img {
        height: 300px;
      }
    }
  }
}

@media screen and ( $mobile-media ) {

  .Arrow {
    transform: scale(0.8);
  }

  .Column {

    .Headline {
      font-size: $fontSize-xl;
    }

    a {
      max-width: 100%;
    }
  }

  .SpaceRemoval {
    margin-bottom: -25px;

    & + [ class *= 'AuthorBox' ] {
      margin-top: 24px;
    }
  }

  .oneColumnRow:not(.oneColumnNoPadding),
  .twoColumnRow,
  .threeColumnRow,
  .fourColumnRow,
  .fourInfoboxColumnRow {
    padding-bottom: 25px;
    padding-top: 25px;
  }

  .twoColumnRow,
  .threeColumnRow,
  .fourInfoboxColumnRow {
    display: grid;
    grid-gap: 25px 20px;
    grid-template-columns: 100%;
    max-width: 100%;
  }

  .qaContainer {
    margin: 25px auto;
    padding: 35px 30px;
  }

  .twoColumnRowNoBreak {
    grid-template-columns: 50% 50%;
    max-width: calc(100% - 20px);
  }

  .twoColumnRowFlip {

    .Column:first-child,
    .ColumnFlexOff:first-child {
      grid-row-start: 2;
    }

    .Column:last-child,
    .ColumnFlexOff:last-child {
      grid-row-start: 1;
    }
  }

  .fourInfoboxColumnRow,
  .fourColumnRow,
  .threeColumnRow {

    .Column {

      a {
        max-width: 100%;
      }
    }
  }

  .RowSlide {

    .RowColumn {

      a {
        max-width: 250px;
      }
    }
  }

  .CmsProductList {

    > div:first-of-type {

      > div {
        margin: 0 !important;
        width: 50%;
      }
    }
  }

  .CmsProductFilterBtn {
    max-width: 100%;
  }

  :global {

    .FullSizeColumnRow {

      img {
        height: 200px;
      }
    }
  }
}
