/* ------------------------------------*\
  #Imports
\*------------------------------------ */
@import '../../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.CmsQACheckbox,
.CmsQARadio,
.CmsQAText {
  margin-bottom: 25px;

  div {
    display: block;
    margin-bottom: 10px;
  }
}

.CmsQAText {

  label {
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
