/* ------------------------------------*\
  #Imports
\*------------------------------------ */
@import '../../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.CmsHeadline {
  font-family: $fontFamily;
  font-size: $fontSize-xxl;
  font-weight: 100;
  -moz-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  margin-bottom: 5px;

  span {
    display: block;
    font-family: $fontFamily;
  }
}

.CmsHeadlineLeft {
  text-align: left;
}

.CmsHeadlineRight {
  text-align: right;
}

.CmsHeadlineCenter {
  text-align: center;
}

h2.CmsHeadline {
  font-family: $fontFamily;
  font-size: $fontSize-xl;
  font-style: normal;

  strong {
    font-weight: $font-weight-m;
  }
}

h3.CmsHeadline {
  font-family: $fontFamily;
  font-size: $fontSize-l;
  font-style: normal;
  font-weight: $font-weight-m;

  strong {
    font-weight: $font-weight-l;
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .CmsHeadline,
  h2.CmsHeadline {
    font-size: $fontSize-lm;
  }

  h3.CmsHeadline {
    font-size: $fontSize-m;
  }
}
