/* ------------------------------------*\
  #Imports
\*------------------------------------ */
@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Badge {
  bottom: 45px;
  cursor: pointer;
  height: 230px;
  position: fixed;
  right: 15px;
  transition-duration: 0.3s;
  width: 150px;
  z-index: 2147483646;

  .BadgeClose {
    opacity: 0;
    position: absolute;
    right: 5px;
    top: 5px;
    transition-duration: 0.3s;
    z-index: 1;

    &:before {
      color: $white;
      font-size: $fontSize-xxl;
    }
  }

  &:hover {

    .BadgeClose {
      opacity: 1;
    }
  }
}

.BadgePlay {
  bottom: 0;
  height: 100px !important;
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(20%, -70%);
  width: 100px !important;
}

.BadgeAnimate {
  opacity: 0;
  pointer-events: none;
}

.BadgeBackground {
  background-position: center;
  background-size: 100%;
  border-color: $white;
  border-radius: 10px;
  border-style: solid;
  border-width: 4px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  display: block;
  height: 100%;
  position: relative;
  width: 100%;
}

.BadgeTextWrapper {
  background: $white;
  bottom: 0;
  color: $purpleDarker;
  display: block;
  line-height: 1.2;
  padding: 10px 5px;
  position: absolute;
  text-align: center;
  width: 100%;
}

.BadgeText {
  display: block;
  font-size: $fontSize-m;
  font-weight: $font-weight-l;
}

.Live, .LivePulse {
  background: $purple;
  border-radius: 10px;
  height: 12px;
  left: 10px;
  position: absolute;
  top: 10px;
  width: 12px;
  z-index: 2;
}

.LivePulse {
  animation-direction: alternate;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-name: pulse;
  animation-timing-function: ease-in-out;
  background: $purpleDarker;
  opacity: .2;
  z-index: 1;
}

@keyframes pulse {

  from {
    transform: scale(1);
  }

  to {
    transform: scale(2);
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $custom-648-media ) {

  .Badge {
    bottom: 5px;
    height: 200px;
    left: 5px;
    width: 130px;

    svg {
      opacity: 1;
    }
  }

  .BadgePlay {
    transform: translate(10%, -70%);
  }
}
