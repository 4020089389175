/* ------------------------------------*\
  #Imports
\*------------------------------------ */
@import '../../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.FrontnowAskButton {
  margin: auto;
  margin-top: 20px;
  min-width: 200px;
  width: auto;
}

.FrontnowEmbeddedWrapper {
  align-items: center;
  background: $greyLightestPlus;
  border-radius: 4px;
  display: flex;
  gap: 80px;
  padding: 48px 48px 48px 64px;

  .FrontnowEmbeddedContent {
    color: $greyDarkest;
    display: flex;
    flex-direction: column;
    width: 100%;

    .FrontnowEmbeddedHeadline {
      font-size: $fontSize-xl;
      font-weight: 500;
      margin-bottom: 32px;
    }
  }

  .FrontnowEmbeddedForm {
    align-items: flex-start;
    display: flex;
    gap: 16px;
    margin-bottom: 16px;

    .EmbeddedFormInput {
      height: 40px;
      width: 100%;

      input {
        background: $white;
        border: solid 1px $greyLightest;
        border-radius: 8px;
        font-size: $fontSize-m;
        padding: 9px 16px;
      }
    }

    .EmbeddedFormButton {
      min-width: 200px;
      width: auto;
    }
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .FrontnowEmbeddedWrapper {
    flex-direction: column;
    gap: 40px;
    padding: 28px;

    .FrontnowEmbeddedForm {
      flex-direction: column;

      .EmbeddedFormButton {
        width: 100%;
      }
    }
  }
}
