/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.HeroSlider {
  font-size: $fontSize-m;
  margin: 0 auto -10px;
  max-height: 430px;
  max-width: $width;
  padding-left: $paddingLeft;
  padding-right: $paddingRight;
  position: relative;

  * {
    outline: none;
  }

  :global {

    .dots {
      bottom: 16px;
    }

    .keen-slider {
      box-sizing: content-box;
      display: flex;
      height: 100%;
      position: relative;
      transition-property: transform;
      width: 100%;
      z-index: 1;
    }

    .keen-slider__slide {
      flex-shrink: 0;
      height: 100%;
      position: relative;
      transition-property: transform;
      width: 100%;
    }
  }
}

.HeroSliderTitleColorWhite {
  color: $white;

  .HeroSliderPoints ul li:before {
    background-image: url('../../../../../assets/images/home/tick_white.svg');
  }
}

.HeroSliderTitleColorBlack {

  .HeroSliderTitle {
    color: $black;
  }
}

.HeroSliderTitleColorPurple {

  .HeroSliderTitle {
    color: $purple;
  }
}

.HeroSliderTitleColorBeige {

  .HeroSliderTitle {
    color: $beige;
  }
}

.HeroSliderTitleColorGrey {

  .HeroSliderTitle {
    color: $grey;
  }
}

.HeroSliderCouponCode {
  border: 1px solid $black;
  display: inline-block;
  margin-top: 10px;
  padding: 5px 15px;
  position: relative;

  &:after {
    background: $purple;
    border-radius: 25%;
    content: '';
    height: 100%;
    left: 0;
    opacity: .2;
    position: absolute;
    top: 4px;
    transform: rotate(2deg);
    width: 100%;
  }
}

.HeroSliderBackground {
  background: no-repeat center;
  background-size: cover;
  display: flex;
  height: 440px;
  padding: 55px 90px;
  position: relative;
  width: 100%;
}

.HeroSliderImg {
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;

  img {
    height: 440px;
    object-fit: cover;
  }
}

.HeroSliderCopyright {
  bottom: 10px;
  color: $black;
  left: 25px;
  max-width: 30%;
  position: absolute;
  text-align: left;
  z-index: 1;
}

.HeroSliderLabel {
  position: absolute;
  right: -128px;
}

.HeroSliderTitle {
  font-family: $fontFamily;
  font-size: $fontSize-title-l;
  line-height: 60px;
  margin-bottom: 10px;

  &.HeroSliderTitleNormal {
    font-family: $fontFamily;
    font-size: $fontSize-title-xl;
    font-style: normal;
    font-weight: $font-weight-s;

    &.HeroSliderTitleXL {
      font-size: 75px;
      line-height: 90px;
    }
  }

  &.HeroSliderTitleXL {
    font-size: 85px;
    line-height: 90px;
  }
}

.HeroSliderImgTitle {

  svg {
    display: inline-block !important;
    height: 60px;
    margin-bottom: 0;
    width: auto;
  }
}

.HeroSliderSubtitle {
  font-weight: $font-weight-l;
  margin-bottom: 15px;
}

.HeroSliderSubtitleNormal {

  .HeroSliderSubtitle {
    font-family: $fontFamily;
    font-size: $fontSize-l;
    font-style: normal;
    line-height: 26px;
  }
}

.HeroSliderSubtitleItalic {

  .HeroSliderSubtitle {
    font-family: $fontFamily;
    font-size: $fontSize-l;
    font-weight: $font-weight-l;
    line-height: 20px;
  }
}

.HeroSliderPoints {

  ul {
    max-width: 385px;
    text-align: left;

    li {
      display: flex;

      &:before {
        background-image: url('../../../../../assets/images/home/tick.svg');
        background-position: left;
        background-repeat: no-repeat;
        background-size: contain;
        content: '';
        margin-top: 3px;
        max-height: 15px;
        max-width: 15px;
        min-width: 15px;
        padding-right: 10px;
      }

      & p {
        font-size: $fontSize-m;
        line-height: 22px;
        margin-bottom: 8px;
      }
    }

    svg {
      flex: 0 0 auto;
      height: 10px;
      margin: 5px 10px 0 0;
      width: 11px;
    }

    b {
      font-weight: $font-weight-m;
      position: relative;
      white-space: nowrap;

      &:after {
        background: $purple;
        border-radius: 25%;
        content: '';
        height: 14px;
        left: 0;
        opacity: 0.2;
        position: absolute;
        top: 4px;
        width: 100%;
      }
    }
  }
}

.HeroSliderContent {
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  max-width: 400px;
  position: relative;
  z-index: 1;
}

.HeroSliderRight {
  justify-content: flex-end;

  .HeroSliderLabel {
    left: -308px;
  }
}

:global {

  .HeroSliderButton {
    display: flex;
    flex: 0 0 auto;
    margin-top: 20px;

    > a {
      min-width: 150px;
      width: auto;
    }
  }
}

.HeroSliderCenter {
  align-items: center;
  justify-content: center;
  text-align: center;

  :global {

    .HeroSliderButton {
      align-self: center;
      justify-content: center;
    }
  }
}

/* slick */

.Arrow {
  background: none;
  border: 0;
  cursor: pointer;
  padding: 0 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;

  &:before {
    color: $purpleDarkest;
    display: block;
    font-size: $fontSize-Icon58;
    position: relative;
    transform: translateX(0);
    transition-duration: 0.4s;
  }
}

.ArrowPrev {
  left: 0;

  &:hover {

    &:before {
      transform: translateX(-10px);
    }
  }
}

.ArrowNext {
  right: 0;

  &:hover {

    &:before {
      transform: translateX(10px);
    }
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .HeroSlider {
    padding: 0;
  }

  .HeroSliderBackground {
    height: 380px;
    padding: 24px;
  }

  .HeroSliderImg {

    img {
      height: 380px;
    }
  }

  .HeroSliderLabel {
    display: none;
  }

  .HeroSliderTitle {

    &.HeroSliderTitleNormal {
      font-size: $fontSize-title-l;
      line-height: 65px;
    }
  }

  .HeroSliderLeft {

    .HeroSliderPoints {
      max-width: 340px;
    }
  }

  :global {

    .HeroSliderButton {

      > a {
        min-width: 130px;
      }
    }
  }

  .ArrowPrev,
  .ArrowNext {
    display: none;
  }
}

@media screen and ( $mobile-media ) {

  .HeroSliderContent {
    flex: 1 1 auto;
  }

  .HeroSliderBackground {
    height: 252px;
    padding: 20px;
  }

  .HeroSliderImg {

    img {
      height: 252px;
    }
  }

  .HeroSliderTitle {
    font-size: $fontSize-xxl;
    line-height: 36px;
    margin-bottom: 5px;

    &.HeroSliderTitleXL {
      font-size: $fontSize-title-l;
      line-height: 70px;
    }

    &.HeroSliderTitleNormal {
      font-size: $fontSize-xxl;
      line-height: 36px;

      &.HeroSliderTitleXL {
        font-size: 55px;
        line-height: 70px;
      }
    }
  }

  .HeroSliderImgTitle {

    svg {
      height: 36px;
      width: auto;
    }
  }

  .HeroSliderSubtitle {
    font-size: $fontSize-m;
    line-height: 20px;
    margin-bottom: 15px;
  }

  .HeroSliderRight {
    text-align: right;

    :global {

      .HeroSliderButton {
        align-self: flex-end;
        float: right;
        margin-top: 5px;
        right: 0;
      }
    }

    .HeroSliderCouponCode {
      float: right;
    }

    .HeroSliderPoints {

      ul {
        display: inline-block;
        text-align: right;
      }
    }
  }

  .buttonMobileHide {

    :global {

      .HeroSliderButton {
        display: none;
      }
    }
  }

  .buttonMobileBottom {

    :global {

      .HeroSliderButton {
        bottom: 16px;
        position: absolute;
      }
    }
  }

  .HeroSliderCenter {
    align-items: flex-start;
  }

  :global {

    .HeroSliderButton {
      margin-top: 15px;
    }
  }
}

@media screen and ( $custom-460-media ) {

  .HeroSliderContent {
    max-width: 320px;
    min-width: calc(100% - 48px);
  }
}
