/* ------------------------------------*\
  #Imports
\*------------------------------------ */
@import '../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.TagNavigationCloseCapture {
  height: 40px;
}

.TagNavigationContainer {
  position: relative;

  .TagNavigationSlide {
    flex-shrink: 1;
    margin-bottom: 5px;
    margin-top: 5px;
  }

  .TagNavigationWrapper {
    position: relative;

    &:after {
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 10%,
      rgba(255, 255, 255, 0.5) 30%, rgb(255, 255, 255) 70%);
      content: '';
      height: 40px;
      position: absolute;
      right: 0;
      top: 0;
      width: 100px;
      z-index: 1;
    }

    :global {

      .keen-slider {
        overflow: hidden;
      }
    }
  }
}

.TagNavigationWrapper,
.SubfieldWrapper {
  padding-left: 1px;

  * {
    outline: none;
  }
}

.TagNavigationWrapper {
  display: block;

  button {
    background: none;
    border: 0;
    cursor: pointer;
    height: 20px;
    width: 20px;

    &:before {
      color: $purpleDarker;
      font-size: $fontSize-lm;
      line-height: 20px;
    }

    &[ aria-label = 'prev' ],
    &[ aria-label = 'next' ] {
      position: absolute;
      top: 10px;
      z-index: 1;
    }

    &[ aria-label = 'prev' ] {
      left: -25px;
    }

    &[ aria-label = 'next' ] {
      right: -25px;
    }
  }

  :global {

    .keen-slider {
      padding-left: 2px;
    }

    .slick {

      &-list {
        padding-left: 2px;
      }

      &-slide {
        margin-right: 10px;
      }
    }
  }
}

.TagNavigation {
  background: $white;
  border: 1px solid $greyLightest;
  border-radius: 8px;
  box-sizing: border-box;
  color: $greyDarkest;
  cursor: pointer;
  display: block;
  font-size: $fontSize-m;
  line-height: 22px;
  margin: 0;
  padding: 3px 27px;
  text-align: center;
  transition: all .3s $defaultBezier;
  white-space: nowrap;

  &:hover,
  &.Active {
    background: $purpleDarker;
    border-color: $purpleDarker;
    color: $white;
  }
}

.SubFieldWrapper {
  background: $white;
  border-radius: 8px;
  box-shadow: 0 0 8px rgba(33, 34, 36, .24);
  color: $greyDarkest;
  display: block;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  max-height: 0;
  max-width: $width;
  min-height: 230px;
  opacity: 0;
  overflow: hidden;
  padding: 20px 25px 80px;
  position: absolute;
  top: 45px;
  transition: .5s;
  visibility: hidden;
  width: 100%;
  z-index: 3;

  .SubFieldBtn {
    background: none;
    border: 0;
    bottom: -100px;
    cursor: pointer;
    left: 50%;
    opacity: 0;
    outline: none;
    position: absolute;
    transform: translateX(-50%);
    transition: .5s;

    &:before {
      color: $purpleDarker;
      font-size: $fontSize-Icon32;
    }
  }

  &.Active {
    max-height: 900px;
    opacity: 1;
    visibility: visible;

    .SubFieldBtn {
      bottom: 15px;
      opacity: 1;
    }
  }

  span {
    display: block;
    font-size: $fontSize-l;
    font-weight: $font-weight-l;
    line-height: 20px;
    margin-bottom: 15px;
  }
}

.SubField {
  display: grid;
  grid-template-columns: 40% 40%;
  justify-items: flex-start;

  p {
    border-radius: 16px;
    font-size: $fontSize-m;
    margin-left: -15px;
    margin-right: 5%;
    min-width: 70%;
    padding: 5px 15px;
    transition: all .2s $defaultBezier;

    a {
      display: block;
    }

    &:hover {
      background: $purpleDarker;
      color: $white;
    }
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .SubfieldWrapper {
    box-shadow: 0 0 8px rgba(33, 34, 36, .11);
  }
}

@media screen and ( $mobile-media ) {

  .TagNavigationContainer {

    .TagNavigationWrapper {

      button {

        &[ aria-label = 'prev' ] {
          left: -15px;
        }

        &[ aria-label = 'next' ] {
          right: -15px;
        }
      }

      :global {

        .slick {

          &-list {
            margin-left: 5px;
          }
        }
      }

      &:after {
        width: 30px;
      }
    }

    .SubField {
      grid-template-columns: 50% 50%;
      row-gap: 10px;

      p {
        font-size: $fontSize-sm;
      }
    }
  }
}
