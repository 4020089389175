/* ------------------------------------*\
  #Imports
\*------------------------------------ */
@import '../../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.CmsImage {
  display: block;
  height: auto;
  max-width: 100%;
  width: 100%;
}

.CmsImageHolder {
  height: 100%;
  position: relative;
}

small {
  color: $greyLighter;
  font-size: $fontSize-xs;
}
