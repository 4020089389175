/* ------------------------------------*\
  #Imports
\*------------------------------------ */
@import '../../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

a.CmsTeaserBoxWrapper {
  margin-top: 0;
}

.WholeLink {
  height: 100%;
  left: 0;
  position: absolute;
  text-indent: -9999px;
  top: 0;
  width: 100%;
  z-index: 3;
}

.CmsTeaserBoxWrapper {
  background: linear-gradient(45deg, #f5f5f5, #ffffff00);
  border: 1px solid $greyLightest;
  box-shadow: 2px 10px 35px -10px rgba(0, 0, 0, 0.2);
  display: block;
  max-height: 260px;
  max-width: 100% !important;
  min-height: 260px;
  position: relative;
  transition: .4s $defaultBezier;
  z-index: 1;

  &:hover {
    box-shadow: 2px 20px 35px -10px rgba(0, 0, 0, 0.07);
  }

  img {
    height: 258px;
    object-fit: cover;
    vertical-align: middle;
  }

  .CmsTeaserLabelWrapper {
    position: absolute;
    right: 10px;
    top: 10px;

    > div:first-of-type {
      margin-bottom: 5px;
    }
  }

  .CmsTeaserPriceWrapper {
    margin-right: -20px;
    margin-top: -20px;

    > div:first-of-type {
      height: 120px;
      transform: rotate(-15deg) scale(0.78);
      width: 120px;
    }
  }

  .CmsTeaserBoxImageContainer {

    :global {

      img {
        display: block;
      }
    }
  }

  .CmsTeaserBoxContent {
    bottom: 15px;
    display: grid;
    padding: 0 10px;
    position: absolute;
    width: 100%;
    z-index: 1;

    > * {
      text-align: center;
    }

    .CmsTeaserBoxTitle {
      font-family: $fontFamily;
      font-size: $fontSize-xl;
      font-weight: 100;
      line-height: 26px;
      padding-top: 5px;
    }

    .CmsTeaserBoxSubline {
      font-family: $fontFamily;
      font-size: $fontSize-sm;
      font-style: normal;
      font-weight: $font-weight-m;
      padding-top: 5px;
    }
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $mobile-media ) {

  .CmsTeaserBoxWrapper {
    max-height: 220px;
    min-height: 220px;

    .CmsTeaserPriceWrapper {
      margin-right: -35px;
      margin-top: -32px;

      > div:first-of-type {
        height: 115px;
        transform: rotate(-15deg) scale(0.68);
        width: 115px;
      }
    }

    img {
      height: 218px;
    }

    .CmsTeaserBoxContent {
      bottom: 10px;
      padding: 0 5px;

      > div {
        font-size: $fontSize-xs;
        line-height: 12px;
      }

      .CmsTeaserBoxTitle {
        font-family: $fontFamily;
        font-size: $fontSize-sm;
        font-style: normal;
        font-weight: $font-weight-m;
        line-height: 20px;
      }

      .CmsTeaserBoxSubline {
        font-size: $fontSize-s;
        line-height: 15px;
        padding-top: 0;
      }
    }
  }
}
