/* ------------------------------------*\
  #Imports
\*------------------------------------ */
@import '../../../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.CmsSubstanceContainer {
  display: block;
}

a.CmsSubstanceItem {
  transition: 0.3s;

  &:hover {
    background-color: $greyLightestPlus;
  }
}

.CmsSubstanceItem {
  align-items: center;
  background-color: $skinLighter;
  border-radius: 50px;
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: 15px;
  margin-right: 15px;
  padding-right: 15px;
  position: relative;

  .CmsSubstanceItemName {
    font-family: $fontFamily;
    font-size: $fontSize-l;
    margin: 0;
    padding: 20px 0 20px 70px;
  }

  > div {
    height: 60px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 60px;

    :global {

      img {
        border-radius: 50px;
        max-width: 60px;
      }
    }
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $mobile-media ) {

  .CmsSubstanceContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .CmsSubstanceItem {

    > div {
      height: 40px;
      width: 40px;

      :global {

        img {
          max-width: 40px;
        }
      }
    }

    .CmsSubstanceItemName {
      font-size: $fontSize-m;
      padding: 11px 0 11px 45px;
    }
  }
}
