/* ------------------------------------*\
  #Imports
\*------------------------------------ */

@import '../../../../../assets/css/variables/variables';

/* ------------------------------------*\
  #Component styles
\*------------------------------------ */

.Product {
  display: grid;
  grid-template-columns: 59.5% 40.5%;
  margin-top: 10px;
}

.ProductTitleWithSummary {
  grid-column: 2;
}

.ProductGalleryWrapper {
  margin-left: auto;
  margin-right: auto;
  max-width: 580px;
  padding: 0 12px 0 0;
  position: relative;
}

.ProductGallery {
  grid-row: 1/4;
  position: relative;
}

.ProductPriceInfoWrapper {
  position: relative;
}

.ProductInfo {
  margin-bottom: 28px;

  @media all and ( $desktop-only-media ) {
    flex: 0 0 428px;
  }
}

.ProductBrand {
  display: block;
  font-size: $fontSize-sm;
  font-weight: $font-weight-s;
  letter-spacing: 0.04em;
  line-height: normal;
  margin-bottom: 5px;
}

.ProductName {
  color: $greyDarkest;
  font-size: $fontSize-xl;
  font-weight: $font-weight-m;
  letter-spacing: -0.01em;
  line-height: 26px;
}

.ProductPriceInfo {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 10px 0;
  position: relative;
}

.ProductContent {
  bottom: 3px;
  font-size: $fontSize-xs;
  font-weight: $font-weight-l;
  line-height: 12px;
  right: 0;
  text-align: right;
}

.ProductFreeShipping {
  font-size: $fontSize-s;
  font-weight: $font-weight-m;
  letter-spacing: .5px;
  line-height: 20px;
}

.ProductShippingPrice {
  padding-bottom: 10px;
}

.ProductFreeShippingBox {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

.ProductFreeTooltip {
  position: relative;
}

.ProductInclTax {
  font-size: $fontSize-s;
  letter-spacing: .5px;
  line-height: 20px;
  text-align: right;

  span {
    cursor: pointer;
    text-decoration: underline;
  }
}

.ProductTaxReduction {
  color: $purpleDarker;
  display: block;
  font-weight: $font-weight-l;
  padding-top: 1px;
  position: absolute;
  right: 0;
  text-align: right;
  z-index: 2;
}

.ProductAddToCart {
  margin-bottom: 17px;
  margin-top: 35px;

  form {
    align-items: center;
    display: flex;
  }

  button {

    span {
      margin-left: 10px;
      position: relative;
      top: 2px;
    }
  }
}

.ProductQuantity {
  margin: 0 10px 0 0;
}

.ProductAddToWishlist {
  margin-bottom: 15px;
  text-align: center;
}

.ProductShortDescription {
  color: $greyDarkest;
  line-height: 20px;
  margin-bottom: 20px;
  margin-top: 20px;

  h2 {
    font-size: $fontSize-l;
    font-weight: $font-weight-m;
  }

  strong {
    font-weight: $font-weight-l;
  }

  ul {
    margin-top: 16px;
  }

  li {
    font-size: $fontSize-sm;
    line-height: 22px;
    margin-bottom: 9px;
    padding-left: 23px;
    position: relative;

    &:before {
      color: $asamGreenDarker;
      font-size: $fontSize-Icon12;
      left: 4px;
      position: absolute;
      top: 6px;
    }
  }
}

.ProductIngredients {
  color: $greyLighter;
  cursor: pointer;
  font-size: $fontSize-s;
  text-decoration: underline;
}

.ProductInfoWrapper {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.ProductSliderWrapper,
.RecommendedSliderWrapper {
  padding-bottom: 100px;
  padding-top: 35px;
}

.RecommendedSliderWrapper {
  border-bottom: 1px solid $greyLightest;
}

.ProductCartButtonFixed {
  bottom: 20px;
  position: fixed;
  right: 16px;
  width: 215px;
  z-index: 9999;
}

.PaypalBox {
  margin-top: 25px;
}

.DeliveryDelayed {
  color: $greyLighter;
  font-size: $fontSize-s;
  letter-spacing: 0.5px;
  line-height: 20px;
  padding: 12px 0 10px 20px;
  position: relative;

  &:before {
    background: $orange;
    border-radius: 50%;
    content: '';
    height: 12px;
    left: 0;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
  }
}

/* ------------------------------------*\
  #Component RWD
\*------------------------------------ */

@media screen and ( $tablet-media ) {

  .Product {
    grid-template-columns: 51.3% 48.7%;
  }

  .ProductInfo {
    padding-left: 3%;
  }

  .ProductSliderWrapper,
  .RecommendedSliderWrapper {
    border-bottom: 0px;
    padding-bottom: 25px;
    padding-top: 0px;
  }
}

@media screen and ( $mobile-media ) {

  .Product {
    display: block;
  }

  .ProductGallery {
    width: 100%;
  }

  .ProductShortDescription {
    margin-top: 0;
  }

  .ProductInfo {
    padding-left: 0;
    padding-top: 20px;
    width: 100%;
  }

  .ProductGalleryWrapper {
    max-width: 400px;
    padding: 0;
  }
}
